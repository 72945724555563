@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

#chatTable {
  /* table-layout: fixed; */
  width: 100%;
  border-collapse: collapse;
}
.messageText {
  white-space: pre-line;
  word-wrap: break-word; /* All browsers since IE 5.5+ */
  overflow-wrap: break-word; /* Renamed property in CSS3 draft spec */
  max-width: 1px;
}

.form-switch .form-check-input:checked {
  background-color: var(--bs-form-check-bg);
}

.form-switch .form-check-input:checked {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
}

#userInput > input,
#userInput > textarea,
#userInput > button {
  border: 1px solid black;
}

#searchInput > input,
#searchInput > button {
  border: 1px solid rgba(255, 100, 100, 1);
}
#searchInput > button {
  color: rgba(255, 255, 255, 0.55);
  background-color: rgba(255, 50, 50, 1);
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* Import styles for image gallery */
@import "~react-image-gallery/styles/css/image-gallery.css";